import React, { createContext, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { BiHomeAlt } from "react-icons/bi";
import { TbActivity } from "react-icons/tb";
import { TbUsers } from "react-icons/tb";
import { VscBellDot } from "react-icons/vsc";
import { AiOutlineSwap } from "react-icons/ai";
import { GiChart, GiWallet } from "react-icons/gi";
import { IoCardOutline } from "react-icons/io5";
import { FaQuestionCircle } from "react-icons/fa";
import { FiSliders } from "react-icons/fi";
import { MdContentPaste } from "react-icons/md";
import { CgController } from "react-icons/cg";
import { RiFindReplaceLine, RiAdminFill } from "react-icons/ri";

import {
	loginUser,
	logoutUser,
	updatePassword,
	updateUser,
	loginUser2FA,
} from "./Actions/AuthActions";

import {
	clearErrors,
	restoreMsg,
	returnErrors,
	getPermission,
} from "./Reducer/ErrorReducer";

import {
	manageCGWallets,
	manageCGWalletHistory,
	manageCGWalletUserHistory,
} from "./Reducer/CgWalletReducer";

import {
	getDataBundle,
	manageDataBundle,
	manageElectricityBundle,
	getElectricityBundle,
	manageCables,
	manageEducationBundle,
	getEducationBundle,
	manageEducationPin,
} from "./Actions/DataActions";

import {
	getReload,
	manageUserActiveness,
	manageUserPrivilege,
	getUserPrivileges,
	getAllUserTransactionAmount,
} from "./Actions/UserActions";

import { getUseCase, manageUpgrade } from "./Reducer/UseCaseReducer";
import { getSettings } from "./Reducer/SettingsReducer";

import { getActivity } from "./Reducer/BizReducer";

import {
	getNotificationsTo,
	getNotificationsFrom,
	readNotification,
	sendNotification,
	getNotificationsUser,
	manageSlider,
	manageManualBanks,
	manageFaqs,
	manageWalletPin,
	manageNotify,
	getSlider,
	getNotify,
} from "./Actions/NotificationAction";

import {
	buyServices,
	getServicesHistory,
	manageFundWallet,
	getManualBonusHistory,
	getWalletHistory,
	manageWallet,
	manageFundWalletFlutterwave,
	generateVirtual,
	getCards,
	getDataHistory,
	converterServices,
	manageFundWalletPaystack,
	manageTransaction,
	manualTransactions,
	manualDirectDebit,
	getServicesHistoryPending,
	getReferrals,
	getWalletStat,
	getWalletStatMonthDay,
} from "./Actions/GeneralAction";
import {
	socketProfile,
	socketOnline,
	socketTransaction,
} from "./Reducer/SocketReducer";

import {
	getCategory,
	manageCategory,
	getBiller,
	manageBiller,
	getProducts,
	manageProducts,
	toggleAvailabilty,
	manageNetwork,
	findProviderStat,
	getBillerFunding,
	getChoosenServices,
	getRpiData,
	getOgdams,
	getRingo,
	getMcd,
	getDataTransactionStat,
	getProviderStat,
	getHonourworld,
	getHusmoBalance,
	findProviderFunding,
	getVtpassBalance,
	getBillerData,
	getDataStationBalance,
	getTeetopBalance,
	getDataMeSolutionBalance,
	getProviderBalance,
	getUzobestData,
} from "./Actions/ProviderAction";
import moment from "moment";
import { manageBlacklist } from "./Reducer/BlacklistReducer";

export const GlobalState = createContext();

const DataProvider = ({
	children,
	clearErrors,
	restoreMsg,
	returnErrors,
	loginUser,
	logoutUser,
	manageUserActiveness,
	updatePassword,
	updateUser,
	getDataBundle,
	manageDataBundle,
	getCategory,
	manageCategory,
	getBiller,
	manageBiller,
	getProducts,
	manageProducts,
	toggleAvailabilty,
	manageSlider,
	getReload,
	manageUserPrivilege,
	getUserPrivileges,
	buyServices,
	socketProfile,
	socketOnline,
	manageElectricityBundle,
	getElectricityBundle,
	manageCables,
	manageNetwork,
	findProviderStat,
	getBillerFunding,
	converterServices,
	getUseCase,
	getSettings,
	getNotificationsTo,
	getNotificationsFrom,
	readNotification,
	sendNotification,
	getNotificationsUser,
	socketTransaction,
	getServicesHistory,
	manageFundWallet,
	getManualBonusHistory,
	getWalletHistory,
	manageWallet,
	manageFundWalletFlutterwave,
	generateVirtual,
	getCards,
	getDataHistory,
	manageFundWalletPaystack,
	getChoosenServices,
	getRpiData,
	getOgdams,
	getRingo,
	getMcd,
	getDataTransactionStat,
	getProviderStat,
	manageTransaction,
	manualTransactions,
	manualDirectDebit,
	getHonourworld,
	getHusmoBalance,
	getServicesHistoryPending,
	manageEducationBundle,
	getEducationBundle,
	getReferrals,
	manageManualBanks,
	manageFaqs,
	findProviderFunding,
	manageWalletPin,
	getVtpassBalance,
	manageUpgrade,
	manageNotify,
	getBillerData,
	getActivity,
	getSlider,
	manageEducationPin,
	getDataStationBalance,
	getAllUserTransactionAmount,
	getPermission,
	manageCGWallets,
	manageCGWalletHistory,
	manageCGWalletUserHistory,
	getTeetopBalance,
	getDataMeSolutionBalance,
	getWalletStat,
	getWalletStatMonthDay,
	getProviderBalance,
	manageBlacklist,
	getNotify,
	getUzobestData,
	loginUser2FA,
}) => {
	const {
		auth,
		errors,
		settings,
		notifications,
		success,
		sliders,
		employees,
		admins,
		activesuspended,
		chosen,
		rpi,
		ringo,
		ogdams,
		dataBundle,
		category,
		biller,
		products,
		data,
		usecase,
		upgrade,
		network,
		transactions,
		wallet,
		user,
		superadmin,
		manager,
		accountant,
		marketer,
		developer,
		reseller,
		topuser,
		allUsers,
		bonus,
		commission,
		airtimes,
		converter,
		socket,
		electricity,
		electricityBundle,
		biz,
		mcd,
		cables,
		cablesBundle,
		activity,
		stat,
		husmo,
		honourworld,
		pendings,
		airtimes_pin,
		education,
		educationBundle,
		vtpass,
		referral,
		faqs,
		funding,
		datastation,
		permission,
		customercare,
		cgwallet,
		cgwalletHistory,
		cgwalletUser,
		teetop,
		datamesolution,
		kemtech,
		moavoxpay,
		clubkonnect,
		connectsms,
		bulk_sms,
		blacklist,
		maskawasub,
		vickybest,
		rossytechs,
		standalone,
		kvdata,
		termii,
		gladtidingsdata,
		uzobest,
		uzobestgsm,
		verify,
		geodnatechsub,
		frankeyconnect,
		legitdataway,
		salompay,
		wapay,
	} = useSelector(state => state);

	let [stateName, setStateName] = useState("");

	let numberWithCommas = (x, a) => {
		if (!x) return;
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, a ? a : ",");
	};

	let nairaSign = <span className="fontInherit">&#8358;</span>;
	let nairaSignNeutral = "₦",
		[generalYears, setGeneralYears] = useState([2023]);

	useEffect(() => {
		let startYear = 2023,
			endYear = Number(moment().format("YYYY")),
			allYears = [];
		for (let y = startYear; y <= endYear; y++) {
			allYears?.push(y);
		}
		setGeneralYears(allYears);
	}, []);

	let sidebarList = [
		{
			name: "Dashboard",
			url: "/dashboard",
			icon: <BiHomeAlt className="icon" size={24} />,
			show: true,
		},
		{
			name: "Users",
			url: "/users",
			icon: <TbUsers className="icon" size={24} />,
			show: true,
		},
		{
			name: "Transactions",
			url: "/transactions",
			icon: <GiChart className="icon" size={24} />,
			show: true,
		},
		{
			name: "Products",
			url: "/products",
			icon: <IoCardOutline className="icon" size={24} />,
			show: true,
		},
		{
			name: "Converter",
			url: "/converter",
			icon: <AiOutlineSwap className="icon" size={24} />,
			show: true,
		},
		{
			name: "Notification",
			url: "/notifications",
			icon: <VscBellDot className="icon" size={24} />,
			show: true,
		},
		{
			name: "Wallet",
			url: "/wallets",
			icon: <GiWallet className="icon" size={24} />,
			show: true,
		},
		{
			name: "Employee",
			url: "/administration",
			icon: <RiAdminFill className="icon" size={24} />,
			show: [
				"Teetop Digital",
				"Kemtech Enterprises",
				"Houseaid",
				"ALLAHU LATEEF INTEGRATED SERVICES LIMITED",
			]?.includes(process.env.REACT_APP_NAME),
		},
		{
			name: "Switcher",
			url: "/switchers",
			icon: <RiFindReplaceLine className="icon" size={24} />,
			show: true,
		},
		// {
		// 	name: "Reports",
		// 	url: "/reports",
		// 	icon: <CiTimer className="icon" size={24} />,
		// },
		// {
		// 	name: "Bundles",
		// 	url: "/bundles",
		// 	icon: <BsBoxSeam className="icon" size={24} />,
		// },
		{
			name: "Slider",
			url: "/sliders",
			icon: <FiSliders className="icon" size={24} />,
			show: [
				"Teetop Digital",
				"Houseaid",
				"Moavox Pay",
				"Kemtech Enterprises",
				"Vickybest Telecom",
				"Fantastic Fisayo",
				"Yema Telecommunications",
				"ALLAHU LATEEF INTEGRATED SERVICES LIMITED",
				"Durable Telecommunications",
				"MySub",
				"Mel Data Services",
				"Giwa Digital",
			]?.includes(process.env.REACT_APP_NAME),
		},
		{
			name: "Faqs",
			url: "/faqs",
			icon: <FaQuestionCircle className="icon" size={24} />,
			show: true,
		},
		{
			name: "Control",
			url: "/controls",
			icon: <CgController className="icon" size={24} />,
			show: true,
		},
		{
			name: "Inventory",
			url: "/inventory",
			icon: <MdContentPaste className="icon" size={24} />,
			show: true,
		},
		{
			name: "Activity",
			url: "/activities",
			icon: <TbActivity className="icon" size={24} />,
			show: true,
		},
	];

	const state = {
		numberWithCommas,
		sidebarList,
		stateName,
		setStateName,
		nairaSign,
		nairaSignNeutral,

		auth,
		loginUser,
		logoutUser,

		errors,
		clearErrors,

		settings,
		updateUser,

		notifications,

		success,
		restoreMsg,
		returnErrors,

		sliders,
		manageSlider,

		employees,
		admins,
		activesuspended,
		chosen,
		rpi,
		ringo,
		ogdams,
		updatePassword,
		dataBundle,
		getDataBundle,
		manageDataBundle,

		category,
		getCategory,
		manageCategory,

		biller,
		getBiller,
		manageBiller,

		products,
		getProducts,
		manageProducts,
		toggleAvailabilty,

		data,
		usecase,
		upgrade,
		network,
		transactions,
		wallet,

		user,
		superadmin,
		manager,
		accountant,
		marketer,
		developer,
		reseller,
		topuser,
		standalone,

		getReload,
		allUsers,
		manageUserActiveness,
		manageUserPrivilege,
		getUserPrivileges,
		buyServices,

		bonus,
		commission,
		airtimes,
		converter,
		airtimes_pin,

		socket,
		socketProfile,
		socketOnline,
		manageEducationBundle,
		getEducationBundle,
		education,
		educationBundle,
		electricity,
		electricityBundle,
		manageElectricityBundle,
		getElectricityBundle,
		manageCables,
		manageNetwork,
		findProviderStat,
		getBillerFunding,
		converterServices,
		biz,
		mcd,
		husmo,
		datastation,
		honourworld,
		rossytechs,
		kvdata,
		gladtidingsdata,
		uzobestgsm,

		cables,
		getUseCase,
		getSettings,
		cablesBundle,
		activity,

		getNotificationsTo,
		getNotificationsFrom,
		readNotification,
		sendNotification,
		getNotificationsUser,
		getNotify,
		socketTransaction,

		stat,
		getServicesHistory,
		manageFundWallet,
		getManualBonusHistory,
		getWalletHistory,
		manageWallet,
		manageFundWalletFlutterwave,
		generateVirtual,
		getCards,
		getDataHistory,
		manageFundWalletPaystack,

		getChoosenServices,
		getRpiData,
		getOgdams,
		getRingo,
		getMcd,
		getDataTransactionStat,
		getProviderStat,
		manageTransaction,
		manualTransactions,
		manualDirectDebit,
		getHonourworld,
		getHusmoBalance,
		getServicesHistoryPending,
		pendings,
		vtpass,

		referral,
		getReferrals,
		manageManualBanks,
		manageFaqs,
		faqs,

		funding,
		findProviderFunding,
		manageWalletPin,
		getVtpassBalance,
		manageUpgrade,
		manageNotify,
		getBillerData,
		getActivity,
		getSlider,
		manageEducationPin,
		getDataStationBalance,
		getAllUserTransactionAmount,
		permission,
		customercare,
		getPermission,
		manageCGWallets,
		manageCGWalletHistory,
		manageCGWalletUserHistory,
		cgwallet,
		cgwalletHistory,
		cgwalletUser,
		getTeetopBalance,
		teetop,
		datamesolution,
		getDataMeSolutionBalance,
		generalYears,
		getWalletStat,
		getWalletStatMonthDay,
		kemtech,
		moavoxpay,
		getProviderBalance,
		clubkonnect,
		connectsms,
		bulk_sms,
		blacklist,
		manageBlacklist,
		maskawasub,
		vickybest,
		termii,
		uzobest,
		getUzobestData,
		verify,
		loginUser2FA,
		geodnatechsub,
		frankeyconnect,
		legitdataway,
		salompay,
		wapay,
	};

	return <GlobalState.Provider value={state}>{children}</GlobalState.Provider>;
};

export default connect(null, {
	loginUser,
	clearErrors,
	logoutUser,
	updateUser,
	restoreMsg,
	returnErrors,
	updatePassword,
	getDataBundle,
	manageDataBundle,
	getCategory,
	manageCategory,
	getBiller,
	manageBiller,
	getProducts,
	manageProducts,
	toggleAvailabilty,
	manageSlider,
	getReload,
	manageUserActiveness,
	manageUserPrivilege,
	getUserPrivileges,
	buyServices,
	socketProfile,
	socketOnline,
	manageElectricityBundle,
	getElectricityBundle,
	manageEducationBundle,
	getEducationBundle,
	manageCables,
	manageNetwork,
	findProviderStat,
	getBillerFunding,
	converterServices,
	getUseCase,
	getSettings,
	getNotificationsTo,
	getNotificationsFrom,
	readNotification,
	sendNotification,
	getNotificationsUser,
	socketTransaction,
	getServicesHistory,
	manageFundWallet,
	getManualBonusHistory,
	getWalletHistory,
	manageWallet,
	manageFundWalletFlutterwave,
	generateVirtual,
	getCards,
	getDataHistory,
	manageFundWalletPaystack,
	getChoosenServices,
	getRpiData,
	getOgdams,
	getRingo,
	getMcd,
	getDataTransactionStat,
	getProviderStat,
	manageTransaction,
	manualTransactions,
	manualDirectDebit,
	getHonourworld,
	getHusmoBalance,
	getServicesHistoryPending,
	getReferrals,
	manageManualBanks,
	manageFaqs,
	findProviderFunding,
	manageWalletPin,
	getVtpassBalance,
	manageUpgrade,
	manageNotify,
	getBillerData,
	getActivity,
	getSlider,
	manageEducationPin,
	getDataStationBalance,
	getAllUserTransactionAmount,
	getPermission,
	manageCGWallets,
	manageCGWalletHistory,
	manageCGWalletUserHistory,
	getTeetopBalance,
	getDataMeSolutionBalance,
	getWalletStat,
	getWalletStatMonthDay,
	getProviderBalance,
	manageBlacklist,
	getNotify,
	getUzobestData,
	loginUser2FA,
})(DataProvider);
