// Root reducer to combine all reducers in the app

import AuthReducer from "./AuthReducer";

import { combineReducers } from "redux";
import ErrorReducer, {
	PermissionReducer,
	SuccessReducer,
} from "./ErrorReducer";
import SettingsReducer, {
	NotificationReducer,
	SliderReducer,
} from "./SettingsReducer";
import UsersReducer, {
	AccountantsReducer,
	AllUsersReducer,
	CustomerCareReducer,
	DevelopersReducer,
	ManagersReducer,
	MarketersReducer,
	ResellersReducer,
	StandalonesReducer,
	SuperadminsReducer,
	TopusersReducer,
} from "./UserReducer";
import {
	BillerReducer,
	CategoryReducer,
	ChoosenReducer,
	NetworkReducer,
	OgdamsReducer,
	ProductReducer,
	RingoReducer,
	RPIDataReducer,
	MCDReducer,
	HusmoReducer,
	HonourworldReducer,
	VtpassReducer,
	DataStationReducer,
	TeetopReducer,
	DataMeSolutionReducer,
	MoavoxpayReducer,
	KemtechReducer,
	ClubKonnectReducer,
	ConnectSMSReducer,
	MaskawasubReducer,
	VickybestReducer,
	RossyTechsReducer,
	KvdataReducer,
	TermiiReducer,
	GladtidingsdataReducer,
	UzobesDataReducer,
	UzobestgsmReducer,
	GeodnatechsubReducer,
	FrankeyconnectReducer,
	LegitdatawayReducer,
	SalompayReducer,
	WapayReducer,
} from "./ProviderReducer";
import DataMainReducer, { DataBundleReducer } from "./DataReducer";
import UseCaseReducer, { UpgradeReducer } from "./UseCaseReducer";
import TransactionsReducer, { PendingTransctions } from "./TransactionsReducer";
import WalletReducer, {
	BonusReducer,
	CommissionReducer,
	ReferralReducer,
} from "./WalletReducer";
import AirtimeReducer, {
	AirtimeConverterReducer,
	AirtimePinReducer,
} from "./AirtimeReducer";
import socketReducer from "./SocketReducer";
import ElectricityReducer, {
	ElectricityBundleReducer,
} from "./ElectricityReducer";
import BizReducer, {
	ActivityReducer,
	BulkSMSReducer,
	VerifyReducer,
} from "./BizReducer";
import CableReducer, { CablesTypesReducer } from "./CableReducer";
import {
	FaqsReducer,
	ProviderFundingReducer,
	ProviderStateReducer,
} from "./StatReducer";
import EducationReducer, { EducationBundleReducer } from "./EducationReducer";
import {
	CgWalletHistoryReducer,
	CgWalletReducer,
	CgWalletUserHistoryReducer,
} from "./CgWalletReducer";
import { BlacklistReducer } from "./BlacklistReducer";

export default combineReducers({
	auth: AuthReducer,
	errors: ErrorReducer,
	settings: SettingsReducer,
	notifications: NotificationReducer,
	success: SuccessReducer,
	sliders: SliderReducer,
	chosen: ChoosenReducer,
	husmo: HusmoReducer,
	datastation: DataStationReducer,
	kvdata: KvdataReducer,
	gladtidingsdata: GladtidingsdataReducer,
	geodnatechsub: GeodnatechsubReducer,
	datamesolution: DataMeSolutionReducer,
	maskawasub: MaskawasubReducer,
	rpi: RPIDataReducer,
	ringo: RingoReducer,
	ogdams: OgdamsReducer,
	honourworld: HonourworldReducer,
	dataBundle: DataBundleReducer,
	category: CategoryReducer,
	biller: BillerReducer,
	products: ProductReducer,
	data: DataMainReducer,
	usecase: UseCaseReducer,
	upgrade: UpgradeReducer,
	network: NetworkReducer,
	transactions: TransactionsReducer,
	pendings: PendingTransctions,
	user: UsersReducer,
	superadmin: SuperadminsReducer,
	manager: ManagersReducer,
	accountant: AccountantsReducer,
	marketer: MarketersReducer,
	developer: DevelopersReducer,
	reseller: ResellersReducer,
	standalone: StandalonesReducer,
	topuser: TopusersReducer,
	customercare: CustomerCareReducer,
	allUsers: AllUsersReducer,
	wallet: WalletReducer,
	bonus: BonusReducer,
	commission: CommissionReducer,
	airtimes: AirtimeReducer,
	airtimes_pin: AirtimePinReducer,
	converter: AirtimeConverterReducer,
	socket: socketReducer,
	electricity: ElectricityReducer,
	electricityBundle: ElectricityBundleReducer,
	education: EducationReducer,
	educationBundle: EducationBundleReducer,
	biz: BizReducer,
	mcd: MCDReducer,
	cables: CableReducer,
	cablesBundle: CablesTypesReducer,
	activity: ActivityReducer,
	stat: ProviderStateReducer,
	vtpass: VtpassReducer,
	referral: ReferralReducer,
	funding: ProviderFundingReducer,
	cgwallet: CgWalletReducer,
	cgwalletHistory: CgWalletHistoryReducer,
	permission: PermissionReducer,
	cgwalletUser: CgWalletUserHistoryReducer,
	faqs: FaqsReducer,
	teetop: TeetopReducer,
	moavoxpay: MoavoxpayReducer,
	kemtech: KemtechReducer,
	clubkonnect: ClubKonnectReducer,
	connectsms: ConnectSMSReducer,
	bulk_sms: BulkSMSReducer,
	blacklist: BlacklistReducer,
	vickybest: VickybestReducer,
	rossytechs: RossyTechsReducer,
	termii: TermiiReducer,
	uzobest: UzobesDataReducer,
	uzobestgsm: UzobestgsmReducer,
	verify: VerifyReducer,
	frankeyconnect: FrankeyconnectReducer,
	legitdataway: LegitdatawayReducer,
	salompay: SalompayReducer,
	wapay: WapayReducer,
});
